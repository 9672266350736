export const formatCurrency = (amount: number, decimalPlace = 2) => {
  const amountNotUndefined = Number(amount) || 0;
  const amountInString = `${amountNotUndefined.toFixed(decimalPlace)}`;
  return amountInString.replace(/\B(?=(\d{3})+(?!\d))/g, ',');
};

export function formatToCurrency(number: number | string, style = 'currency') {
  return (number || 0)?.toLocaleString('en-NG', {
    style,
    currency: 'NGN',
    minimumFractionDigits: 0,
  });
}

export const handleTotal = (amount: number) => {
  return amount > 0 ? amount : 0;
};

export const getFileFormat = (path: string) => {
  const fileDetails = path.split('.');
  return fileDetails[fileDetails.length - 1];
};

export const RequestStatus = (status: string): string => {
  const statusObject: Record<string, string> = {
    NEW: 'Pending',
    APPROVED: 'Pending',
    PAID: 'Paid',
    LOGISTICS_ACCEPTED: 'Logistics Notified',
    DEVICE_PICKED_UP: 'In Transit',
    DEVICE_DELIVERED_SC: 'Repair Center',
    REPAIR_IN_PROGRESS: 'Under Repairs',
    REPAIR_COMPLETE: 'Repair Complete',
    DROPOFF_SCHEDULED: 'Ready Delivery',
    DEVICE_PICKED_UP_SC: 'In Transit',
    DEVICE_DELIVERED_USER: 'Completed',
    ORDER_CANCELLED: 'Cancelled',
  };

  return statusObject[status] || 'Unknown';

  // return status;
};

export const RequestStatusColor = (status: string): string => {
  const statusObject: Record<string, string> = {
    NEW: 'red',
    APPROVED: 'red',
    PAID: 'blue',
    LOGISTICS_ACCEPTED: '#8B8000',
    DEVICE_PICKED_UP: '#8B8000',
    DEVICE_DELIVERED_SC: '#8B8000',
    REPAIR_IN_PROGRESS: '#8B8000',
    REPAIR_COMPLETE: 'blue',
    DROPOFF_SCHEDULED: '#8B8000',
    DEVICE_PICKED_UP_SC: '#8B8000',
    DEVICE_DELIVERED_USER: 'green',
    ORDER_CANCELLED: 'red',
  };

  return statusObject[status] || 'red';
};

export const toBase64 = (file: File) =>
  new Promise((resolve, reject) => {
    const reader = new FileReader();
    reader.readAsDataURL(file);
    reader.onload = () => resolve(reader.result);
    reader.onerror = reject;
  });

export function formatFileSize(totalBytes: number) {
  if (totalBytes < 1000000) {
    return Math.floor(totalBytes / 1000) + 'KB';
  }

  return Math.floor(totalBytes / 1000000) + 'MB';
}

export function xhrRequest({
  method,
  url,
  data,
  onProgress,
  onReadyStateChange,
}: // onCancel,
{
  method: string;
  url: string;
  data: any;
  onProgress: (e: any) => void;
  onReadyStateChange: (e: any) => unknown;
}) {
  const xhr = new XMLHttpRequest();

  xhr.open(method, url, true);

  xhr.upload.onprogress = onProgress;

  xhr.onreadystatechange = onReadyStateChange;

  // xhr.upload.onabort = onCancel;

  xhr.send(data);
  return xhr;
}

export const KYC_TYPES = Object.freeze({
  '': {
    label: 'Select ID',
    value: '',
  },
  VNIN: {
    label: 'Virtual National Identification Number (VNIN)',
    value: 'VNIN',
  },
  VIN: {
    label: 'Voters Card Identification Number (VIN)',
    value: 'VIN',
  },
  PASSPORT: {
    label: 'International Passport',
    value: 'PASSPORT',
  },
  DRIVERLICENCE: {
    label: "Driver's Licence",
    value: 'DRIVERLICENCE',
  },
});

export const GENDER_TYPES = Object.freeze({
  '': {
    label: 'Select gender',
    value: '',
  },
  MALE: {
    label: 'Male',
    value: 'MALE',
  },
  FEMALE: {
    label: 'Female',
    value: 'FEMALE',
  },
});

export const getErrorMessage = (error: any): string => {
  if (error?.response) {
    /* The request was made
    the server responded with a status code that falls out of range of 2xx */
    return (
      error.response.data.message ||
      error.response.data.error ||
      error.response.data.detail ||
      'Something went wrong'
    );
  }

  if (error?.data) {
    return error.data.message;
  }

  if (error?.message) {
    // Something happened in setting up the request that triggered an error
    return error.message;
  }

  if (error?.request) {
    // The request was made but no response was received
    return 'Something went wrong';
  }
  return '';
};

export const planStatus = (item: string) => {
  switch (item) {
    case 'PENDING':
      return { text: 'Pending', color: 'yellow.500' };
    case 'ONBOARDED':
      return { text: 'Onboard', color: 'green' };
    case 'PAID':
      return { text: 'Paid', color: 'blue' };
    default:
      return { text: 'Pending', color: 'yellow.500' };
  }
};

export const careStatus = (item: {
  onboarding_completed: boolean;
  onboarding_approved: boolean;
  onboarding_cancelled: boolean;
}) => {
  if (item.onboarding_approved) {
    return { text: 'Approved', color: 'green' };
  } else if (item?.onboarding_cancelled) {
    return { text: 'Cancelled', color: 'red' };
  } else if (item?.onboarding_completed) {
    return { text: 'Completed', color: 'blue' };
  } else return { text: 'Pending', color: 'yellow.500' };
};
