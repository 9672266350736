import gql from 'graphql-tag';

export const GET_INSURANCE_PLANS = gql`
  query GetInsurancePlans($partnerCode: String!) {
    insurance_plan(
      order_by: { price: asc }
      where: { partner: { code: { _eq: $partnerCode } } }
    ) {
      id
      price
      description
      name
      partner_id
      planType
      plan_info
      plan_ussd_code
      cover_value
      plan_tnc
      data_value
      user_plans {
        reference_number
      }
    }
  }
`;

export const GET_INSURANCE_PLAN_BY_ID = gql`
  query InsurancePlanByID($id: uuid!) {
    insurance_plan_by_pk(id: $id) {
      id
      price
      description
      name
      partner_id
      planType
      plan_info
      plan_ussd_code
      cover_value
      data_value
      plan_tnc
    }
  }
`;

export const GET_USER_PLANS = gql`
  query GetUserPlans($where: user_plan_bool_exp) {
    user_plan(where: $where, order_by: { created_at: desc }) {
      id
      created_at
      status
      insurance_plan {
        id
        name
        price
        planType
        plan_ussd_code
        description
        plan_info
      }
    }
  }
`;

export const GET_USER_PLAN_BY_ID = gql`
  query GetUserPlanByID($id: uuid!) {
    user_plan_by_pk(id: $id) {
      id
      user {
        email
        phone
        id
      }
      insurance_plan {
        id
        name
        price
        planType
        description
        plan_info
        plan_ussd_code
        data_value
        cover_value
      }
      reference_number
    }
  }
`;

export const GET_SUMMARY_DETAILS = gql`
  query GetUserPlanByPk($id: uuid!, $deviceModel: uuid!) {
    user_plan_by_pk(id: $id) {
      id
      insurance_plan {
        id
        name
        price
        planType
        plan_ussd_code
        description
        data_value
      }
    }
    device_by_pk(id: $deviceModel) {
      id
      name
    }
  }
`;

export const USER_DEVICE_PLAN = gql`
  query GetUserDevicePlan($id: uuid) {
    user_device_plan_by_pk(id: $id) {
      kyc_link
    }
  }
`;

export const USER_DEVICE_PLANS = gql`
  query GetUserDevicePlans($where: user_device_plan_bool_exp) {
    user_device_plan(where: $where, order_by: { created_at: desc }) {
      id
      kyc_link
      onboarding_approved
      user_device {
        device {
          name
          id
          category_id
          brand_id
        }
        imei_check {
          imei
        }
        serial_number
      }
      user_plan {
        id
        status
        insurance_plan {
          cover_value
          name
          planType
          price
        }
      }
      user {
        phone
      }
      onboarding_completed
      onboarding_cancelled
      created_at
    }
  }
`;

export const DEVICE_CATEGORIES = gql`
  query {
    category {
      name
      id
      icon_alias
      description
      hasSubCategory
    }
  }
`;

export const CLAIMS_HISTORY = gql`
  query ClaimsHistory($where: claims_transaction_bool_exp) {
    claims_transaction(where: $where) {
      id
      created_at
      request {
        request_services {
          id
          description
        }
        request_services_aggregate {
          aggregate {
            count
          }
        }
      }
      status
    }
  }
`;

export const MTN_DEVICE_CATEGORIES = gql`
  query {
    category(where: { name: { _ilike: "mobile phones" } }) {
      name
      id
      icon_alias
      description
      hasSubCategory
    }
  }
`;

export const DEVICE_CARE_RECENT = gql`
  query GetDeviceCareRecent($id: uuid) {
    user_device_plan(
      order_by: { created_at: desc }
      limit: 4
      where: { user_id: { _eq: $id } }
    ) {
      id
      user_device {
        device {
          name
          id
        }
      }
      kyc_link
      onboarding_approved
      onboarding_completed
      onboarding_cancelled
      created_at
    }

    user_plan(
      order_by: { created_at: desc }
      limit: 4
      where: { user_id: { _eq: $id }, status: { _eq: PAID } }
    ) {
      id
      status
      insurance_plan {
        planType
        name
        description
      }
      created_at
    }
  }
`;

export const GET_USER_BY_PHONE = gql`
  query GetUserByPhone($phone: String) {
    user(where: { phone: { _eq: $phone } }) {
      email
      phone
    }
  }
`;

export const VIDEO_KYC_STATUS = gql`
  query VideoKycStatus($kyc_link: String!) {
    user_device_plan(where: { kyc_link: { _eq: $kyc_link } }) {
      onboarding_approved
      kyc_link
      videoByConditionVideoId {
        video_link
      }
      videoByImeiVideoId {
        video_link
      }
      videoByLivelinessVideoId {
        video_link
      }
    }
  }
`;
