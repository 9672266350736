// eslint-disable-next-line @typescript-eslint/ban-ts-comment
// @ts-nocheck
import {
  Avatar,
  Box,
  Flex,
  Heading,
  IconButton,
  Show,
  Stack,
  Text,
  useBreakpointValue,
} from '@chakra-ui/react';
import React from 'react';
import { BiLeftArrowAlt, BiRightArrowAlt } from 'react-icons/bi';

import Slider from 'react-slick';

const settings = {
  dots: true,
  arrows: false,
  // fade: true,
  infinite: true,
  autoplay: true,
  speed: 500,
  autoplaySpeed: 5000,
  slidesToShow: 1,
  slidesToScroll: 1,
};

type IReviews = {
  title: string;
  text: string;
  image: string;
  name: string;
  location: string;
}[];

const getTextByPartner = () => {
  switch (process.env.NEXT_PUBLIC_PARTNER_CODE) {
    case 'MTNNG':
      return 'My MTNPicknFix';
    case 'AIRNG':
      return 'Airtel PicknFix';
    default:
      return 'PicknFix';
  }
};

export const reviews: IReviews = [
  {
    title: 'Outstanding Service and Quick Repairs!',
    text: `"I was worried about finding a reliable place to repair my phone, but ${getTextByPartner()} exceeded my expectations. The service was outstanding, and my phone was fixed in no time. I highly recommend their repair services to anyone in need of quick and efficient repairs!"`,
    image: 'images/review-seun-bolarinwa.webp',
    name: 'Seunfunmi Bolarinwa',
    location: 'Lagos Nigeria',
  },
  {
    title: 'Affordable and Efficient!',
    text: `"${getTextByPartner()} saved me a lot of money with their affordable repair services. I was pleasantly surprised by how fast they fixed my laptop. It's working like new again. Great service and excellent customer care!"`,
    image: 'images/review-damilola-shomade.webp',
    name: 'Damilola Shomade',
    location: 'Lagos Nigeria',
  },
  {
    title: 'Reliable and Professional!',
    text: `"I've had my devices repaired at ${getTextByPartner()} several times, and they always deliver top-notch service. The services are done by 100% Manufacturer Authorised professional, and the repair work is reliable. I trust them completely with my gadgets."`,
    image: 'images/review-seun-ajisafe.webp',
    name: 'Ajisafe Seun',
    location: 'Lagos Nigeria',
  },
  {
    title: 'Excellent Stuff!',
    text: `"${getTextByPartner()} App gives me the kind of convenience of using Uber. Excellent stuff"`,
    image: 'images/photo-avatar-02_compressed.webp',
    name: 'Olu Jacobs',
    location: 'Lagos Nigeria',
  },
  {
    title: 'Comprehensive Device Care Plans!',
    text: `"The device care plan from ${getTextByPartner()} is a lifesaver! It covers all my devices and gives me peace of mind knowing that any repair needs will be taken care of promptly and professionally. Highly recommend their care plans!"`,
    image: '',
    name: 'Picknfix User',
    location: 'Lagos Nigeria',
  },
  {
    title: 'Fast, Friendly, and Efficient!',
    text: `"${getTextByPartner()} provides excellent customer service. The staff is friendly, and they fixed my tablet faster than I expected. I'm impressed with their efficiency and quality of work. I'll definitely be back for any future repairs."`,
    image: '',
    name: 'Picknfix User',
    location: 'Lagos Nigeria',
  },
  {
    title: 'Great Value for Money!',
    text: `"I opted for the ${getTextByPartner()} device care plan, and it has been a great investment. Not only does it save me money on repairs, but it also ensures that my devices are always in good working condition. ${getTextByPartner()} offers great value for money!"`,
    image: '',
    name: 'David H.',
    location: 'Lagos Nigeria',
  },
  {
    title: 'Top-Notch Customer Service!',
    text: `"From the moment I booked service in ${getTextByPartner()}, I was treated with 100% professionalism. The customer service is top-notch, and they kept me informed throughout the repair process."`,
    image: '',
    name: 'Picknfix User',
    location: 'Lagos Nigeria',
  },
  {
    title: `Highly Recommend ${getTextByPartner()}!`,
    text: `"I can't thank ${getTextByPartner()} enough for their exceptional repair service. My phone had a cracked screen, and they fixed it perfectly. The staff is knowledgeable and courteous. I highly recommend ${getTextByPartner()} to anyone in need of repairs."`,
    image: '',
    name: 'Picknfix User',
    location: 'Lagos Nigeria',
  },
  {
    title: `Peace of Mind with Care Plans!`,
    text: `"With ${getTextByPartner()}'s device care plan, I don't have to worry about unexpected repair costs. Their plans are very affordable. It's great to know that my devices are covered and in good hands."`,
    image: '',
    name: 'Picknfix User',
    location: 'Lagos Nigeria',
  },
  {
    title: `Efficient and Professional Repairs!`,
    text: `"I was impressed by the efficiency and professionalism of ${getTextByPartner()}. They repaired my gaming console quickly, and it works perfectly now. The staff is knowledgeable and friendly. I highly recommend their services!"`,
    image: '',
    name: 'Picknfix User',
    location: 'Lagos Nigeria',
  },
];

const Carousel = () => {
  const [slider, setSlider] = React.useState<Slider | null>(null);

  const top = useBreakpointValue({ base: '90%', md: '50%' });
  const side = useBreakpointValue({ base: '30%', md: '10px' });

  return (
    <Box
      position={'relative'}
      min-height={'600px'}
      width={'full'}
      overflow={'hidden'}
      bg="#262b2c"
      py={5}
      mb={10}
    >
      {/* CSS files for react-slick */}
      <link
        rel="stylesheet"
        type="text/css"
        // charSet="UTF-8"
        href="https://cdnjs.cloudflare.com/ajax/libs/slick-carousel/1.6.0/slick.min.css"
      />
      <link
        rel="stylesheet"
        type="text/css"
        href="https://cdnjs.cloudflare.com/ajax/libs/slick-carousel/1.6.0/slick-theme.min.css"
      />
      {/* Left Icon */}
      <Show above="sm">
        <IconButton
          aria-label="left-arrow"
          colorScheme="messenger"
          borderRadius="full"
          position="absolute"
          left={side}
          top={top}
          transform={'translate(0%, -50%)'}
          zIndex={2}
          onClick={() => slider?.slickPrev()}
        >
          <BiLeftArrowAlt />
        </IconButton>
        {/* Right Icon */}
        <IconButton
          aria-label="right-arrow"
          colorScheme="messenger"
          borderRadius="full"
          position="absolute"
          right={side}
          top={top}
          transform={'translate(0%, -50%)'}
          zIndex={2}
          onClick={() => slider?.slickNext()}
        >
          <BiRightArrowAlt />
        </IconButton>
      </Show>
      {/* Slider */}
      <Slider {...settings} ref={(slider) => setSlider(slider)}>
        {reviews.map((review, index) => (
          <Box key={index} as="section" p={[2, 0]}>
            <Stack
              h="full"
              align="center"
              justify="center"
              maxW="1200px"
              marginX="auto"
              color="white"
              spacing={5}
            >
              <Heading lineHeight="normal" fontSize="4xl" textAlign={'center'}>
                {review.title}
              </Heading>
              <Text lineHeight="normal" textAlign="center" fontSize={'2xl'}>
                {review.text}
              </Text>
              <Avatar size={'xl'} src={review.image} />

              <Box>
                <strong>{`${review.name}`}</strong>
              </Box>
            </Stack>
          </Box>
        ))}
      </Slider>
      <Show below="md">
        <Flex justifyContent="center" alignItems="center" gap={4}>
          <IconButton
            aria-label="left-arrow"
            colorScheme="messenger"
            borderRadius="full"
            zIndex={2}
            onClick={() => slider?.slickPrev()}
          >
            <BiLeftArrowAlt />
          </IconButton>
          {/* Right Icon */}
          <IconButton
            aria-label="right-arrow"
            colorScheme="messenger"
            borderRadius="full"
            zIndex={2}
            onClick={() => slider?.slickNext()}
          >
            <BiRightArrowAlt />
          </IconButton>
        </Flex>
      </Show>
    </Box>
  );
};

export default Carousel;
